.split {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    padding: 30px;
    font-family: var(--rubik);
    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: 1024px) {
        padding: 43px 60px 21px 118px;
        grid-template-columns: minmax(max-content, 270px) 1fr minmax(max-content, 337px);
        grid-template-rows: 1fr;
        align-items: center;
    }
}

.caseStudies {
    max-width: 245px;
    grid-row: 2;
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
        grid-row: 1;
    }
    @media screen and (min-width: 1024px) {
        margin-bottom: initial;
    }
}

.picture {
    justify-content: center;
    transform: translate(4px, 20px);
    display: none;
    @media screen and (min-width: 1024px) {
        display: flex;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}

.details {
    grid-row: 1;
    margin-bottom: 30px;
    @media screen and (min-width: 1024px) {
        grid-column: 3;
        padding-left: 10px;
        margin-bottom: initial;
        transform: translateY(-8px);
    }
}

.desc {
    color: var(--secondary-max-text-color, #cddbe5);
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 24px;
}

.logo {
    margin-top: 30px;
    margin-bottom: 0;
}
