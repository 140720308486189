.sales {
    max-width: 251px;
    margin-bottom: 9.5px;
    .number {
        font-size: 40px;
        color: var(--primary-max-brand-color-300, #fc5b1a);
        font-weight: 700;
        margin: 0 0 5px;
        display: flex;
        flex-direction: column;
        span {
            font-size: 14px;
        }
    }
    .desc {
        color: var(--primary-max-text-color, #fff);
        font-size: 14px;
        font-weight: 600;
        margin: 0;
    }
}
