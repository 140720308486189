.button {
    font-size: 17px;
    border-radius: 15px;
    border: 2px solid var(--primary-max-brand-color-400, #de4d14);
    color: var(--primary-btn-text-color);
    background: var(--primary-max-brand-color-300, #fc5b1a);
    @media only screen and (min-width: 768px) {
        font-size: 13px;
        padding: 0.7em 1.46em;
        margin-right: 8px;
    }
    &:hover,
    &:active {
        background: var(--primary-max-brand-color-400, #de4d14);
        color: #fff;
        border-color: var(--primary-max-brand-color-400, #de4d14);
    }
}
