.link {
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    font-weight: 600;
    &.blue {
        color: #00a1ff;
        &:hover,
        &:active,
        &:focus {
            color: #fc5b1b;
        }
    }
}
