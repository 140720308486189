html:is(*, .fakeClass) {
    --bg-solid-color: #000f19;
    --primary-max-brand-color-300: #fc5b1a;
    --primary-max-brand-color-400: #de4d14;
    --secondary-max-brand-color: #00a2ff;
    --primary-max-text-color: #fff;
    --secondary-max-text-color: #cddbe5;
    --black-max-color: #000;
}

html:is(*, .theme-high-contrast) body {
    /* ... */
}
