@keyframes zoomOut {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    to {
        opacity: 0;
    }
}
@keyframes bounceIn {
    from,
    50%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    60% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}
.button {
    font-size: 17px;
    font-weight: 500;
    border-radius: 100px;
    box-sizing: border-box;
    padding: 0.5em 1.24em;
    letter-spacing: 0.04em;
    transition: all 0.1s ease-in-out;
    line-height: 1;
    margin: auto;
    text-decoration: none;
    animation: none;
    pointer-events: auto;
    @media only screen and (min-width: 768px) {
        padding: 0.6em 1.24em;
        line-height: 1.4;
    }

    &.bounceIn {
        @media screen and (min-width: 768px) {
            animation-fill-mode: both;
            animation-name: bounceIn;
            animation-duration: 0.3s;
        }
    }
    &.zoomOut {
        pointer-events: none;
        @media screen and (min-width: 768px) {
            animation-fill-mode: both;
            animation-name: zoomOut;
            animation-duration: 0.4s;
        }
    }
}
