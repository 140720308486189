.underline {
    --private-padding-right: var(--underline-offset-right, 0);
    --private-padding-left: var(--underline-offset-left, 0.2em);
    --private-underline-color: var(--underline-color, #01a2ff);
    --private-underline-size: var(--underline-size, 0.106em);
    --private-border-bottom: var(--underline-offset-bottom, 0.001em);

    /* --private-underline-size: var(--underline-size, 0.4em); */
    --private-underline-offset: var(--underline-offset, -0.1em);
    text-decoration: none;
    box-decoration-break: clone;
    padding: 0 var(--private-padding-right) 0 var(--private-padding-left);
    margin: 0 calc(-1 * var(--private-padding-right)) 0 calc(-1 * var(--private-padding-left));
    background-image: radial-gradient(closest-side, var(--private-underline-color) 0%, var(--private-underline-color) 99.99%, transparent 99.99%),
        radial-gradient(closest-side, var(--private-underline-color) 0%, var(--private-underline-color) 99.99%, transparent 99.99%),
        url("data:image/svg+xml,%3Csvg viewBox='0 0 560.29 47.46' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m560.28 7c-.11 2.41-.02.67 0 3.64-.09 0-.75-.09-1.32-.12-.19 0-.4 0-.63-.02-.41-.01-.89-.03-1.45-.04-.35-.01-.73-.03-1.14-.04-.45 0-.95-.02-1.46-.04-44.93-1.34-345.65-8.25-551.45 36.86-.13.03-.25.04-.36.07-.5.1-1.01.15-1.52.15-.26 0-.53-.01-.79-.04h-.05c-.11-5.83-.11-4.32-.11-8.98 0-3.33 0-1.79 0-4.61 0-3.34.13-2.81.16-2.81.22-.09.47-.09.7-.13 64.3-12 178.29-26.75 363.53-30.19 95.45-1.77 171.29.31 190.94.94.26.01.51.01.74.01.41.01.79.03 1.14.04.56.01 1.04.03 1.45.04.23.01.44.01.63.01.57.03.91.04.99.04-.03 2.39.06 1.13-.03 3.55' fill='%2301a2ff'/%3E%3C/svg%3E");
    background-position: 0 calc(100.2% + var(--private-border-bottom)), 100% calc(100% - var(--private-underline-size) * 2.35 + var(--private-border-bottom)),
        calc(var(--private-underline-size) * 0.5) calc(100% + var(--private-border-bottom));
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: var(--private-underline-size) var(--private-underline-size), calc(var(--private-underline-size) * 0.5) calc(var(--private-underline-size) * 0.5),
        calc(100% - calc(var(--private-underline-size) * 0.7)) calc(var(--private-underline-size) * 3);
    color: inherit;
    padding-bottom: var(--private-underline-offset);
    margin-bottom: -0.1em;
    margin-left: calc(-1 * var(--private-padding-left));
    margin-right: calc(-1 * var(--private-padding-right));
    border-bottom: var(--private-border-bottom) solid transparent;
    &:empty {
        background: none;
    }
    &.orange {
        --private-underline-color: var(--underline-color, #fd733c);
        background-image: radial-gradient(closest-side, var(--private-underline-color) 0%, var(--private-underline-color) 99.99%, transparent 99.99%),
            radial-gradient(closest-side, var(--private-underline-color) 0, var(--private-underline-color) 99.99%, transparent 99.99%),
            url("data:image/svg+xml,%3Csvg viewBox='0 0 560.29 47.46' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m560.28 7c-.11 2.41-.02.67 0 3.64-.09 0-.75-.09-1.32-.12-.19 0-.4 0-.63-.02-.41-.01-.89-.03-1.45-.04-.35-.01-.73-.03-1.14-.04-.45 0-.95-.02-1.46-.04-44.93-1.34-345.65-8.25-551.45 36.86-.13.03-.25.04-.36.07-.5.1-1.01.15-1.52.15-.26 0-.53-.01-.79-.04h-.05c-.11-5.83-.11-4.32-.11-8.98 0-3.33 0-1.79 0-4.61 0-3.34.13-2.81.16-2.81.22-.09.47-.09.7-.13 64.3-12 178.29-26.75 363.53-30.19 95.45-1.77 171.29.31 190.94.94.26.01.51.01.74.01.41.01.79.03 1.14.04.56.01 1.04.03 1.45.04.23.01.44.01.63.01.57.03.91.04.99.04-.03 2.39.06 1.13-.03 3.55' fill='%23fd733c'/%3E%3C/svg%3E");
    }
    &.max-orange {
        --private-underline-color: var(--underline-color, #fc5b1a);
        background-image: radial-gradient(closest-side, var(--private-underline-color) 0%, var(--private-underline-color) 99.99%, transparent 99.99%),
            radial-gradient(closest-side, var(--private-underline-color) 0, var(--private-underline-color) 99.99%, transparent 99.99%),
            url("data:image/svg+xml,%3Csvg viewBox='0 0 560.29 47.46' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m560.28 7c-.11 2.41-.02.67 0 3.64-.09 0-.75-.09-1.32-.12-.19 0-.4 0-.63-.02-.41-.01-.89-.03-1.45-.04-.35-.01-.73-.03-1.14-.04-.45 0-.95-.02-1.46-.04-44.93-1.34-345.65-8.25-551.45 36.86-.13.03-.25.04-.36.07-.5.1-1.01.15-1.52.15-.26 0-.53-.01-.79-.04h-.05c-.11-5.83-.11-4.32-.11-8.98 0-3.33 0-1.79 0-4.61 0-3.34.13-2.81.16-2.81.22-.09.47-.09.7-.13 64.3-12 178.29-26.75 363.53-30.19 95.45-1.77 171.29.31 190.94.94.26.01.51.01.74.01.41.01.79.03 1.14.04.56.01 1.04.03 1.45.04.23.01.44.01.63.01.57.03.91.04.99.04-.03 2.39.06 1.13-.03 3.55' fill='%23fc5b1a'/%3E%3C/svg%3E");
    }
    &.small {
        --private-padding-right: var(--underline-offset-right, 0);
        --private-padding-left: var(--underline-offset-left, 0);
        --private-underline-size: var(--underline-size, 0.045em);
        background-size: calc(var(--private-underline-size) * 2.2), calc(var(--private-underline-size) * 1.455), calc(100% - calc(var(--private-underline-size) * 1.5)) calc(var(--private-underline-size) * 3);
        background-position: 0 calc(100% + var(--private-border-bottom) * 3), 100% calc(100% - var(--private-underline-size) * 1.31 + var(--private-border-bottom)),
            calc(var(--private-underline-size) * 1) calc(100% + var(--private-border-bottom));
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 892 892' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m446 892c-246.32 0-446-199.68-446-446s199.68-446 446-446' fill='%23000'/%3E%3C/svg%3E"),
            url("data:image/svg+xml,%3Csvg viewBox='0 0 892 892' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m446 0c246.32 0 446 199.68 446 446s-199.68 446-446 446' fill='%23000'/%3E%3C/svg%3E"),
            url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' viewBox='0 0 288.68 23.48' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3CclipPath id='a'%3E%3Cpath d='m288.68 23.48h-288.68v-24.36h288.68z'/%3E%3C/clipPath%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='m295.38 6.65c.16-1.87-.92-5.45-6.81-5.68-24.05-.92-237.44-2.7-288.89 5.52-.24.03-.47.08-.7.14-.03 0-.06 0-.09.02-3.33.71-5.91 3.36-6.52 6.71-.84 4.57 3.23 11.59 9.55 9.83 44.88-12.51 247.99-13.61 285.96-11.42 5.12.3 7.34-3.26 7.5-5.12' fill='%23000'/%3E%3C/g%3E%3Cpath d='m0 0h288.68v23.48h-288.68z' fill='none'/%3E%3C/svg%3E");
        &.max-orange {
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 892 892' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m446 892c-246.32 0-446-199.68-446-446s199.68-446 446-446' fill='%23fc5b1a'/%3E%3C/svg%3E"),
                url("data:image/svg+xml,%3Csvg viewBox='0 0 892 892' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m446 0c246.32 0 446 199.68 446 446s-199.68 446-446 446' fill='%23fc5b1a'/%3E%3C/svg%3E"),
                url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' viewBox='0 0 288.68 23.48' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3CclipPath id='a'%3E%3Cpath d='m288.68 23.48h-288.68v-24.36h288.68z'/%3E%3C/clipPath%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='m295.38 6.65c.16-1.87-.92-5.45-6.81-5.68-24.05-.92-237.44-2.7-288.89 5.52-.24.03-.47.08-.7.14-.03 0-.06 0-.09.02-3.33.71-5.91 3.36-6.52 6.71-.84 4.57 3.23 11.59 9.55 9.83 44.88-12.51 247.99-13.61 285.96-11.42 5.12.3 7.34-3.26 7.5-5.12' fill='%23fc5b1a'/%3E%3C/g%3E%3Cpath d='m0 0h288.68v23.48h-288.68z' fill='none'/%3E%3C/svg%3E");
        }
    }
}
