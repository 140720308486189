.industry {
    font-family: var(--rubik);
    font-size: 14px;
    color: var(--secondary-max-text-color, #cddbe5);
    margin-bottom: 35px;
    h4 {
        margin-top: 0;
        margin-bottom: 4px;
        font-weight: 400;
    }
    p {
        margin: 0;
        font-weight: 700;
    }
}
