@import url('./schema-theme.css');

body:is(*, .fakeClass) {
    background-color: var(--bg-solid-color, #000f19);
    background-image: url('../../../public/pages/max/global/bg1.svg'), url('../../../public/pages/max/global/bg2.svg'), url('../../../public/pages/max/global/bg1.svg');

    /* Podgląd do ustawiania pozycji gradientów background-image: url('../../../public/pages/max/global/preview_01.svg'), url('../../../public/pages/max/global/preview_02.svg'), url('../../../public/pages/max/global/preview_03.svg'); */
    background-size: 1534px 1534px, 1367px 1367px, 1534px 1534px;
    background-position: calc(50% - 223px) -144px, calc(50% + 161px) 261px, calc(50% - 0px) 1072px;
    background-repeat: no-repeat;
    background-origin: padding-box, padding-box;
    padding-bottom: 60px;
    font-family: var(--rubik);
    margin-top: calc(-1 * var(--header-height));
    border-top: var(--header-height) solid transparent;
    color: var(--primary-max-text-color, #fff);
    --header-height: 75px;
}

:global(.theme-high-contrast) body:is(*, .fakeClass) {
    background-image: none;
}
