.mfooter-legal {
    ul {
        list-style: none;
        gap: 0 20px;
        display: flex;
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            flex-basis: 100%;
            text-align: center;
            white-space: nowrap;
            @media screen and (min-width: 768px) {
                flex-basis: auto;
                text-align: start;
            }
            a {
                font-family: var(--rubik);
                font-size: 12px;
                line-height: 1.4;
                color: #000;
                text-decoration: none;
                &:hover {
                    color: #00baff;
                    @supports (--css: variables) {
                        color: var(--primary-link-focus-color, #00baff);
                    }
                }
            }
        }
    }
}
.darkMode {
    ul li a {
        color: var(--secondary-max-text-color, #cddbe5);
    }
}
